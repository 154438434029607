body,
html,
#root,
.app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}
* {
  font-family: "Metrophobic", sans-serif;
}
.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    width: 150px;
    height: 150px;
    animation: rotate 2s linear infinite;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
hr {
  background-color: lightgray;
  height: 1px;
  border: none;
}
a {
  text-decoration: none;
  color: black;
}
.app {
  display: flex;
  justify-content: center;
}
button {
  cursor: pointer;
}
body {
  padding-left: 60px;
  width: auto;
  box-sizing: border-box;
  // overflow-y: hidden;
}
a {
  text-decoration: none;
}
