@import "./palette.scss";

.login-main {
  background-color: $color2;
  width: 100%;
  height: 100vh;
  padding: 15px 30px 50px 30px;
  box-sizing: border-box;
  > .login-content {
    width: 500px;
    height: 350px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $color2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p {
        font-size: 40px;
        color: white;
    }
    > hr {
        border: 1px solid $color3;
        width: 50%;
        margin-top: -25px;
        margin-bottom: 50px;
    }
    > div {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      > input {
        width: 80%;
        height: 40px;
        font-size: 20px;
        text-align: center;
        border: none;
        outline: none;
        &:focus {
          color: $color3;
        }
      }
    }
    > button {
      width: 200px;
      height: 40px;
      font-size: 20px;
      box-sizing: border-box;
      cursor: pointer;
      background-color: $color3;
      outline: none;
      border: none;

      margin-top: 40px;
    }
  }
}
