@import "./palette.scss";

.new-main {
  background-color: $color4;
  width: 100%;
  height: 100vh;
  padding: 15px 30px 50px 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  > .new-personal-info {
    width: 25%;
    > * {
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > select,
      input {
        border-radius: 5px;
        border: 1px solid lightgray;
        height: 80%;
        outline: none;
      }
      > .voivodeship, select {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 159px;
        height: 28px;
      }
    }
  }
  > .new-product-info {
    width: 25%;
    height: 75%;
    > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      > select,
      input,
      textarea {
        width: 250px;
        outline: none;
        padding: 0;
        margin: 0;
        text-align: center;
        border-radius: 5px;
        border: 1px solid lightgray;
        height: 80%;
      }
      > textarea {
        height: 100px;
      }
    }
    :last-child {
      align-items: flex-start;
    }
  }
  .new-order-submit {
    position: absolute;
    bottom: 200px;
    background-color: $color3;
    color: black;
    font-weight: bold;
    font-style: italic;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    height: 50px;
    width: 200px;
  }
}
