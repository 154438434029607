@import "./palette.scss";

.deliverytimes-main {
  background-color: $color4;
  width: 100%;
  height: 100vh;
  padding: 15px 30px 50px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    > .grid-item {
      border: 1px solid #ccc;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > input {
        text-align: center;
        border-radius: 5px;
        padding: 5px 0px;
        outline: none;
        border: none;
      }
    }
  }
}
