@import "./palette.scss";

.map-menu {
  z-index: 999;
  width: 500px;
  background-color: $color2;
  opacity: 0.7;
  border-radius: 0px 0px 30px 30px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .map-searchbar {
    width: 80%;
    height: 40px;
    box-sizing: border-box;
  }
  > button {
    margin-top: 20px;
    background-color: $color3;
    color: black;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    font-size: 15px;
    width: 40%;
    height: 40px;
    cursor: pointer;
  }
  > p {
    width: 100%;
    color: red;
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    display: none;
  }
}
.active {
  opacity: 1;
}
