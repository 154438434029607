@import "./palette.scss";

.order-main {
  background-color: $color4;
  width: 100%;
  height: 100vh;
  padding: 15px 30px 50px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  > .order-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > hr {
    width: 100%;
  }
  > .order-data {
    padding: 50px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    // border: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    > div {
      height: 100%;
      width: 100%;
    }
    > .order-fullinfo {
      flex: 8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      > div {
        height: 100%;
        width: 100%;
        padding: 50px;
        box-sizing: border-box;
        > p {
          margin: 4px 0px;
          word-wrap: break-word;
        }
      }
      > .order-customerinfo {
        flex: 4;
        height: 100%;
        display: flex;
        flex-direction: column;
        > .order-firstinfo {
          margin-top: auto;
        }
      }
      > .order-productinfo {
        flex: 7;
      }
    }
    > .order-shortinfo {
      flex: 3;
      padding: 30px;
      box-sizing: border-box;
      > p {
        margin: 4px 0px;
      }
    }
  }
  > .order-manage {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > button,
    a {
      background-color: $color3;
      color: black;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      margin: 0px 5px;
      padding: 0px 10px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      font-weight: bold;
      > svg {
        fill: $color1;
        scale: 1.2;
        margin-right: 5px;
      }
    }
    > .order-navigatepreviousnext {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      > a.order-previous {
        margin-right: 100px;
      }
      > a {
        display: flex;
        background-color: $color4;
        font-size: larger;
        // align-items: flex-end;
        > svg {
          margin: 0px 10px;
        }
      }
    }
  }
  .order-deletealert {
    position: absolute;
    padding: 70px 0px;
    box-sizing: border-box;
    width: 1200px;
    height: 400px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: auto;
    background-color: $color2;
    border-radius: 50px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    display: none;
    > p {
      color: $color3;
      font-size: 30px;
    }
    > .order-deletealertoptions {
      width: 50%;
      display: flex;
      justify-content: space-between;
      > button {
        background-color: $color3;
        border-radius: 10px;
        border: 5px;
        height: 50px;
        padding: 0px 20px;
        font-weight: bold;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
  .show {
    display: flex;
  }
}
