@import "./palette.scss";

.main {
  background-color: $color4;
  width: 100%;
  height: 100vh;
  padding: 15px 30px 50px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  > *:not(hr) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  hr {
    width: 100%;
  }
  > .header {
    height: 100px;
    > .header-options {
      > .create-order {
        background-color: $color3;
        border: none;
        height: 35px;
        padding: 0px 20px;
        font-weight: bold;
        font-size: 17px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
        > svg {
          padding-right: 3px;
        }
        &:hover {
          cursor: pointer;
          scale: 1.05;
        }
      }
    }
  }
  > .filter {
    height: 80px;
    > .filter-col1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      > .searchbar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $color5;
        padding: 10px;
        width: 300px;
        border-radius: 5px;
        > .search-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          > svg {
            fill: $color3;
          }
        }
        > .search-field {
          border: none;
          width: 100%;
          &:focus-visible {
            outline: none;
          }
          padding: 0px 15px;
        }
      }
    }
    > .filter-col2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      > * {
        margin-left: 50px;
      }
      > .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $color5;
        padding: 10px;
        border-radius: 5px;
        > .status-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          > svg {
            fill: $color3;
          }
        }
        > .status-select {
          border: none;
          width: 100%;
          padding: 0px 15px;
          > option {
            text-transform: capitalize;
            border: none;
            padding: 10px;
          }
          &:focus-visible {
            outline: none;
          }
        }
      }
      > .voivodeship {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $color5;
        padding: 10px;
        border-radius: 5px;
        > .voivodeship-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          > svg {
            fill: $color3;
          }
        }
        > .voivodeship-select {
          border: none;
          width: 100%;
          padding: 0px 15px;
          > option {
            text-transform: capitalize;
            border: none;
            padding: 10px;
          }
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }
  > .sort {
    height: 60px;
    > * {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > p {
        padding-right: 3px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        color: gray;
      }
      > svg {
        fill: gray;
      }
      &:hover {
        cursor: pointer;
      }
    }
    > .clear-filters {
      width: 150px;
      height: 50px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      background-color: $color3;
      border: none;
      font-weight: bold;
      font-size: 18px;
      font-style: italic;
      border-radius: 0px 0px 10px 10px;
      transition: all 0.2s ease-in-out;
      &:hover {
        scale: 1.15;
        cursor: pointer;
      }
    }
    > .visible {
      display: block;
    }
    > .not-visible {
      display: none;
    }
  }
  > .content {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    > div:not(.spinner-container) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px;
        padding: 10px 0px;
        &:hover {
          cursor: pointer;
          background-color: $color2;
          color: white;
          border-radius: 5px;
          > .status-green {
            color: black;
            background-color: rgba(0, 128, 0, 1);
          }
          > .status-gray {
            color: black;
            background-color: rgba(128, 128, 128, 1);
          }
        }
        > .content-status {
          font-weight: bold;
          text-transform: uppercase;
          border-radius: 5px;
        }
        > .status-green {
          color: green;
          background-color: rgba(0, 128, 0, 0.5);
        }
        > .status-gray {
          color: gray;
          background-color: rgba(128, 128, 128, 0.5);
        }
        > .content-agent {
          text-transform: uppercase;
        }
        > *:not(.square) {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@keyframes in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes out {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
