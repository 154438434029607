@import "./palette.scss";

.navigation {
  position: fixed;
  left: 0;
  z-index: 999;
  background-color: $color2;
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  > *:not(hr, .categories-icon) {
    padding: 40px 0px;
    width: 100%;
    > * {
      width: 100%;
      height: 30px;
      transition: all 0.2s ease-in-out;
    }
    > *:hover {
      scale: 1.15;
      cursor: pointer;
    }
  }
  > .refresh-icon {
    > a {
      margin: 40px 0px;
      > svg {
        animation-name: out;
        animation-duration: 0.5s;
        width: 100%;
        height: 30px;
        transition: all 0.2s ease-in-out;
      }
      > svg:hover {
        scale: 1.15;
        cursor: pointer;
        animation-name: in;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-play-state: running;
      }
    }
  }
  > .categories-icon {
    padding: 20px 0px;
    width: 100%;
    > * {
      width: 100%;
      height: 30px;
      transition: all 0.2s ease-in-out;
    }
    > *:hover {
      scale: 1.15;
      fill: $color3;
      cursor: pointer;
    }
  }
  > hr {
    width: 50%;
    height: 1px;
    margin: 40px 0px;
  }
  > .user-icon {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    > *:hover {
      scale: 1.15;
      fill: $color3;
    }
  }
}
